import Vue from 'vue';
import App from './App.vue';
import Vant from 'vant';
import router from '@/routers';
import store from  '@/stores';
import _ from 'lodash';

import '@/utils/rem'; //rem自适应配置文件
import '@/assets/styles/reset.css';  // 重置样式
import 'vant/lib/index.css';//vant组件样式
import '@/utils/routerGuards'; //引入main.js

// import eruda from  'eruda'
// eruda.init();

Vue.config.productionTip = false
Vue.use(Vant);
Vue.use(_);



new Vue({
  router,store,    
  render: h => h(App),
}).$mount('#app')
