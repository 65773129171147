/**
 * 移动端自适应rem配置文件
 * @designWidth 设计宽度
 * @maxWidth 最大宽度
 */
;
(function (designWidth, maxWidth) {
  let timer; //计时器
  let w = window; //窗口
  let d = document; //文档
  let dE = document.documentElement; //元素
  let remStyle = document.createElement('style'); //创建rem样式
  function getNewRem() {
    let phoneWidth = dE.getBoundingClientRect().width //设备宽度
    maxWidth = maxWidth || 540
    phoneWidth > maxWidth && (phoneWidth = maxWidth) //如果设备宽度大于最大宽度，就让设备宽度=750||540
    let rem = phoneWidth * 100 / designWidth
    remStyle.innerHTML = 'html{font-size:' + rem + 'px;}' //设置全局字体尺寸
  }
  if (dE.firstElementChild) {
    dE.firstElementChild.appendChild(remStyle)
  } else {
    let remBox = d.createElement('div')
    remBox.appendChild(remStyle)
    d.write(remBox.innerHTML)
    remBox = null
  }
  getNewRem()
  w.addEventListener('resize', function () {
    clearTimeout(timer) // 防止执行两次
    timer = setTimeout(getNewRem, 300)
  }, false)
  w.addEventListener('pageshow', function (e) {
    if (e.persisted) { // 浏览器后退的时候重新计算
      clearTimeout(timer)
      timer = setTimeout(getNewRem, 300)
    }
  }, false)
  if (d.readyState === 'complete') {
    d.body.style.fontSize = '16px'
  } else {
    d.addEventListener('DOMContentLoaded', function (e) {
      d.body.style.fontSize = '16px'
    }, false)
  }
})(375, 750)