import router from '@/routers'
import {
  Toast
} from 'vant'
/* 全局前置导航守卫 */
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('accessToken');
  if (to.path === '/mine/login') return next(); //使用return，不需要写else
  if (to.path === '/clue/clueScan') return next(); //使用return，不需要写else
  if (to.path === '/clue/clueProjectScan') return next(); //使用return，不需要写else
  if (token) return next(); // 如果有Token
  Toast('未登录，请先登录！');//如果没有Token提示跳转登录页
  router.push('/mine/login');
})