import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter)
/* 配置 */
const routes = [
  /* 路由重定向 */
  {
    path: '/',
    redirect: '/mine/login'
  },
  /* 登陆模块 */
  {
    path: '/mine',
    component: () => import('@/views/mine'),
    children: [{
      path: 'login',
      component: () => import('@/views/mine/login')
    }]
  },
  // 首页
  {
    path: "/home",
    component: () => import('@/views/home'),
    children: [{
      path: 'homeInfo',
      meta: "首页",
      component: () => import('@/views/home/homeInfo')
    }]
  },
  //房源
  {
    path: "/resource",
    component: () => import('@/views/resource'),
    children: [{
        path: 'resourceList',
        component: () => import('@/views/resource/resourceList')
      },
      {
        path: 'resourceInfo',
        component: () => import('@/views/resource/resourceInfo')
      },
      {
        path: 'resourceVacantList',
        component: () => import('@/views/resource/resourceVacantList')
      },
    ]
  },
  //线索
  {
    path: "/clue",
    component: () => import('@/views/clue'),
    children: [{
        path: 'clueChange', // 线索转交
        component: () => import('@/views/clue/clueChange')
      },
      {
        path: 'clueEdit',// 线索新建编辑
        component: () => import('@/views/clue/clueEdit')
      },
      {
        path: 'clueFollow', // 线索跟进
        component: () => import('@/views/clue/clueFollow')
      },
     
      {
        path: 'clueInfo', // 线索详情
        component: () => import('@/views/clue/clueInfo')
      },
      {
        path: 'clueList',
        component: () => import('@/views/clue/clueList')
      },
      {
        path: 'clueScan', // 扫码看房
        component: () => import('@/views/clue/clueScan')
      },
      {
        path: 'clueProjectScan', // 园区扫码看房
        component: () => import('@/views/clue/clueProjectScan')
      },
      {
        path: 'clueSign', // 线索签约
        component: () => import('@/views/clue/clueSign')
      }
    ]
  },
  //合同
  {
    path: "/contract",
    component: () => import('@/views/contract'),
    children: [{
        path: 'contractType',
        component: () => import('@/views/contract/contractType')
      },
      {
        path: 'contractList',
        component: () => import('@/views/contract/contractList')
      },
      {
        path: 'contractInfo',
        component: () => import('@/views/contract/contractInfo')
      },
      {
        path: 'contractExit',
        component: () => import('@/views/contract/contractExit')
      },
      {
        path: 'reserveList',
        component: () => import('@/views/contract/reserveList')
      },
      {
        path: 'reserveInfo',
        component: () => import('@/views/contract/reserveInfo')
      },
      {
        path: 'homeContractList',
        component: () => import('@/views/contract/homeContractList')
      },
      {
        path: 'homeBillList',
        component: () => import('@/views/contract/homeBillList')
      },
    ]
  },
  //收款
  {
    path: "/bill",
    component: () => import('@/views/bill'),
    children: [{
        path: 'billInfo',
        component: () => import('@/views/bill/billInfo')
      },
      {
        path: 'billList',
        component: () => import('@/views/bill/billList')
      },
    ]
  },
  //审批
  {
    path: "/task",
    component: () => import('@/views/task'),
    children: [{
        path: 'taskList',
        component: () => import('@/views/task/taskList')
      },
      {
        path: 'taskInfo',
        component: () => import('@/views/task/taskInfo')
      },
      {
        path: 'wxTaskInfo',
        component: () => import('@/views/task/wxTaskInfo')
      },
    ]
  },
  //我的
  {
    path: "/myself",
    component: () => import('@/views/myself'),
    children: [{
        path: 'myselfInfo',
        component: () => import('@/views/myself/myselfInfo')
      },
      {
        path: 'myselfSetting',
        component: () => import('@/views/myself/myselfSetting')
      },
      {
        path: 'myselfRole',
        component: () => import('@/views/myself/myselfRole')
      },
      {
        path: 'myselfPrivacy',
        component: () => import('@/views/myself/myselfPrivacy')
      },
      {
        path: 'myselfUser',
        component: () => import('@/views/myself/myselfUser')
      },
      {
        path: 'wxAuth',
        component: () => import('@/views/myself/wxAuth')
      },
    ]
  },
  /* 错误页面 */
  {
    path: '/error',
    component: () => import('@/components/error')
  },
  /* 错误重定向 */
  {
    path: '*',
    redirect: '/error'
  }
]
const router = new VueRouter({
  routes,
  mode: 'hash'
})
export default router